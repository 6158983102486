<template>
  <div class="main-wrapper">
    <h1>Статьи на сайте</h1>
    <NewsList/>
  </div>
</template>

<script>
import NewsList from "@/components/news/NewsList.vue";

export default {
  name: 'ArticleList',
  components: {
    NewsList
  },
}
</script>

<style lang="scss" scoped>

</style>
